<template>
  <div>
    <div
      v-if="isAutoblock && numberOfTiles === 1 && !isMobile"
      class="grid w-full bg-white animate-pulse"
    >
      <div
        class="h-[288px] col-span-2 flex border border-solid border-gray-300 bg-white"
      >
        <div class="col-span-1 h-[288px] w-[454px] shrink-0 bg-gray-300"></div>
        <div
          class="flex flex-col w-full h-full col-span-1 overflow-hidden p-md gap-y-sm"
        >
          <div class="h-4 bg-gray-300 w-[200px]"></div>
          <div class="h-4 bg-gray-300 w-[100px]"></div>
          <div class="h-4 bg-gray-300 w-[200px]"></div>
          <div class="h-4 bg-gray-300 w-[300px]"></div>
          <div class="h-4 bg-gray-300 w-[200px]"></div>
          <div class="h-4 mt-auto ml-auto bg-gray-300 w-[100px]"></div>
        </div>
      </div>
    </div>
    <div
      v-else-if="isProduct"
      class="grid w-full grid-cols-1 bg-white md:grid-cols-4 gap-md animate-pulse"
    >
      <div
        v-for="(n, index) in numberOfTiles"
        :key="index"
        class="h-[600px] col-span-1 flex flex-col border border-solid border-gray-300 bg-white"
      >
        <div class="h-[256px] shrink-0 w-full bg-gray-300"></div>
        <div class="flex flex-col h-full overflow-hidden p-md gap-y-sm">
          <div class="h-4 bg-gray-300 w-[200px]"></div>
          <div class="h-4 bg-gray-300 w-[100px]"></div>
          <div class="h-4 bg-gray-300 w-[150px]"></div>
          <div class="h-4 bg-gray-300 w-[200px]"></div>
          <div class="h-4 bg-gray-300 w-[150px]"></div>
          <div class="h-4 mt-auto ml-auto bg-gray-300 w-[100px]"></div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="grid w-full grid-cols-1 bg-white md:grid-cols-3 gap-x-sm gap-y-md animate-pulse"
    >
      <div
        v-for="(n, index) in numberOfTiles"
        :key="index"
        class="h-[600px] col-span-1 flex flex-col border border-solid border-gray-300 bg-white"
      >
        <div class="h-[256px] shrink-0 w-full bg-gray-300"></div>
        <div class="flex flex-col h-full overflow-hidden p-md gap-y-sm">
          <div class="h-4 bg-gray-300 w-[200px]"></div>
          <div class="h-4 bg-gray-300 w-[100px]"></div>
          <div class="h-4 bg-gray-300 w-[200px]"></div>
          <div class="h-4 bg-gray-300 w-[300px]"></div>
          <div class="h-4 bg-gray-300 w-[200px]"></div>
          <div class="h-4 mt-auto ml-auto bg-gray-300 w-[100px]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core';

const breakpoints = useBreakpoints(breakpointsTailwind);
const isMobile = breakpoints.isSmaller('md');

defineProps({
  numberOfTiles: {
    type: Number,
    required: false,
    default: 3,
  },
  isAutoblock: {
    type: Boolean,
    required: false,
    default: false,
  },
  isProduct: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
